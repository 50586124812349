<template>

    <div class="page-content">
        <div class="container-fluid">
                
        </div>
    </div>

</template>

<script>
// import iziToast from 'izitoast';

export default {
    name: 'EditOrNew',
    components: {
        //
    },
    data(){
        return {    
            //
        }
    },
    mounted() {},
    created() {
        //
    },
    methods: {
        //
    },
}
</script>
